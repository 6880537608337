import { Paper, Typography } from "@material-ui/core";
import axios from "axios";
import React, { useState, useEffect } from "react";
import useStyles from "./styles";
import Loader from "../Loader";
import OfferForm from "../OfferForm";
import PayForm from "../PayForm";
import TicketForm from "../TicketForm";
import CallForm from "../CallForm";
import CallRequestForm from "../CallRequestForm";

async function createTicket(data) {
  const res = await axios.post(
    "https://eventpay.ru/api/form/create_ticket",
    data
  );
  console.log(res.data);
  return res.data;
}

async function createCall(data) {
  const res = await axios.post(
    "https://eventpay.ru/api/form/create_call",
    data
  );
  console.log(res.data);
  return res.data;
}

async function getTicketData(ticketUUID) {
  const res = await axios.get(
    `https://eventpay.ru/api/form/ticket/${ticketUUID}`
  );
  console.log(res.data);
  return res.data;
}

function declOfNum(n, titles) {
  return titles[
    n % 10 === 1 && n % 100 !== 11
      ? 0
      : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)
      ? 1
      : 2
  ];
}

const OfferFrame = ({ gameFilter }) => {
  const classes = useStyles();

  const [activeStep, setActiveStep] = useState(0);
  const [updatingData, setUpdatingData] = useState(false);
  const [formData, setFormData] = useState({});
  const [ticketData, setTicketData] = useState({});
  const [selectedData, setSelectedData] = useState({});
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const submitFormPay = async (data) => {
    setLoading(true);
    setSelectedData(data);
    try {
      let ticketDataRes = await createTicket(data);
      if (!ticketDataRes.success) {
        if (ticketDataRes.error) {
          let errorArray = ticketDataRes.error.split("#");
          let errorName = errorArray[0];
          if (errorName == "Place Reserved" && errorArray.length > 1) {
            let placesVacant = parseInt(errorArray[1]);
            setErrorMessage(
              placesVacant > 0
                ? `Сожалеем, на эту дату осталось ${placesVacant} ${declOfNum(
                    placesVacant,
                    ["место", "места", "мест"]
                  )}.`
                : `Сожалеем, все места проданы. Рекомендуем выбрать другую дату.`
            );
          } else if (errorName == "Incorrect Promo") {
            setErrorMessage(`Сожалеем, промокод недействителен`);
          } else {
            setErrorMessage("Произошла неизвестная ошибка, попробуйте снова");
          }
        }
        throw "Ticket Create Failed";
      }
      setTicketData(ticketDataRes.data);
      if (ticketDataRes.data.paid) setActiveStep(2);
      else setActiveStep(1);
      setLoading(false);
      setUpdatingData(true);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const submitFormCall = async (data) => {
    setLoading(true);
    setSelectedData(data);
    try {
      let ticketDataRes = await createCall(data);
      if (!ticketDataRes.success) {
        if (ticketDataRes.error) {
          let errorArray = ticketDataRes.error.split("#");
          let errorName = errorArray[0];
          if (errorName == "Incorrect Promo") {
            setErrorMessage(`Сожалеем, промокод недействителен`);
          } else {
            setErrorMessage("Произошла неизвестная ошибка, попробуйте снова");
          }
          // setErrorMessage("Произошла неизвестная ошибка, попробуйте снова");
        }
        throw "Call Create Failed";
      }
      setActiveStep(3);
      setLoading(false);
      setUpdatingData(true);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const updateTicketData = async () => {
    if (ticketData && ticketData.ticket_uuid) {
      let newTicketData = await getTicketData(ticketData.ticket_uuid);
      if (newTicketData && newTicketData.ticket) {
        setTicketData(newTicketData.ticket);
        if (newTicketData.ticket.paid) {
          setActiveStep(2);
          setUpdatingData(false);
        }
      }
    }
  };

  const closeError = () => {
    setErrorMessage("");
  };

  const requestCall = () => {
    setActiveStep(4);
  };

  const onBackToPay = () => {
    setActiveStep(0);
  };

  const Form = () => {
    if (loading) {
      return <Loader />;
    } else {
      switch (activeStep) {
        case 0:
          return (
            <OfferForm
              formData={formData}
              selectedData={selectedData}
              submitFormPay={submitFormPay}
              submitFormCall={submitFormCall}
              requestCall={requestCall}
              closeError={closeError}
              errorMessage={errorMessage}
              gameFilter={gameFilter}
            />
          );
        case 1:
          return (
            <PayForm ticketData={ticketData} ticketID={ticketData.ticket_id} />
          );
        case 2:
          return <TicketForm ticketData={ticketData} />;
        case 3:
          return <CallForm />;
        case 4:
          return (
            <CallRequestForm
              formData={formData}
              selectedData={selectedData}
              submitFormPay={submitFormPay}
              submitFormCall={submitFormCall}
              closeError={closeError}
              onBack={onBackToPay}
              errorMessage={errorMessage}
              gameFilter={gameFilter}
            />
          );
        default:
          return (
            <OfferForm
              formData={formData}
              selectedData={selectedData}
              submitFormPay={submitFormPay}
              submitFormCall={submitFormCall}
              closeError={closeError}
              errorMessage={errorMessage}
              gameFilter={gameFilter}
            />
          );
      }
    }
  };

  useEffect(() => {
    axios.get("https://eventpay.ru/api/form").then((res) => {
      setFormData(res.data);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    let interval;
    if (updatingData)
      interval = setInterval(async () => {
        await updateTicketData();
      }, 5000);
    return () => {
      if (interval) clearInterval(interval);
      console.log("Stop:", updatingData);
    };
  }, [updatingData]);

  return (
    <>
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          {![3, 4].includes(activeStep) && (
            <Typography variant="h4" align="center" gutterBottom>
              Запись на игру
            </Typography>
          )}
          {[3, 4].includes(activeStep) && (
            <Typography variant="h4" align="center" gutterBottom>
              Заказать звонок
            </Typography>
          )}
          <Form />
        </Paper>
      </main>
    </>
  );
};

export default OfferFrame;
