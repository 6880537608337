import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import OfferFrame from "../OfferFrame";
import CertificateFrame from "../CertificateFrame";
import SuccessFrame from "../SuccessFrame";
import AgreementFrame from "../AgreementFrame";

import "./style.css";

function App() {
	return (
		<>
			<Router>
				<Switch>
					<Route path="/agreement">
						<AgreementFrame />
					</Route>
					<Route path="/success">
						<SuccessFrame />
					</Route>
					<Route path="/certificate">
						<CertificateFrame />
					</Route>
					<Route path="/smart">
						<OfferFrame gameFilter="Smart City"/>
					</Route>
					<Route path="/">
						<OfferFrame gameFilter=""/>
					</Route>
				</Switch>
			</Router>
		</>
	);
}

export default App;
