import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Button,MobileStepper } from "@material-ui/core";

import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
	cardIcon: {
		fontSize: theme.spacing(20),
		margin: "0 auto",
		display: "block",
	},
	buttonsContainer: {
		marginTop: theme.spacing(1),
		background: "#ffffff00",
	},
    bottomHint:{
        marginTop: theme.spacing(2),
    }
}));

export default function CertificateForm({certificateData}) {
	const classes = useStyles();

	const openCertificatePage = (code) => {
		window.open(`https://eventpay.ru/api/certificate/${certificateData.certificate_uuid}-${code}`, "_blank");
	};

	let ii = 0;

	return (
		<>
			<Typography variant="h5" align="center" gutterBottom>
				Информация отправлена на почту {certificateData.email}
			</Typography>
			<Grid container direction="column" justify="center" alignItems="center">
				{certificateData.promos.map((promo)=>{
				ii++;
				return <Button style={{marginBottom:8}} size="large" variant="outlined" onClick={()=>{openCertificatePage(promo.promo_id.code)}}>
					Скачать сертификат №{ii}
				</Button>
				})}
			</Grid>
			<MobileStepper
				className={classes.buttonsContainer}
				variant="dots"
				steps={3}
				position="static"
				activeStep={2}
				nextButton={
					<Button
						variant="outlined"
						size="small"
						style={{ visibility: "hidden" }}
					>
						К оплате <KeyboardArrowRight />
					</Button>
				}
				backButton={
					<Button
						variant="outlined"
						size="small"
						style={{ visibility: "hidden" }}
					>
						<KeyboardArrowLeft /> Назад
					</Button>
				}
			/>
		</>
	);
}
