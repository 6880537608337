import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useForm, FormProvider } from "react-hook-form";
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  MobileStepper,
  Typography,
  DialogActions,
} from "@material-ui/core";
import OfferTextField from "../OfferTextField";
import OfferPhoneField from "../OfferPhoneField";
import { KeyboardArrowLeft, PhoneCallback } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  buttonsContainer: {
    marginTop: theme.spacing(3),
    background: "#ffffff00",
  },
}));

export default function CallRequestForm({
  submitFormCall,
  selectedData,
  errorMessage,
  closeError,
  onBack,
}) {
  const classes = useStyles();
  const methods = useForm();

  const [showErrors, setShowErrors] = useState(false);

  useEffect(() => {
    if (selectedData.name) {
      methods.setValue("name", selectedData.name);
      methods.setValue("phone", selectedData.phone);
      // methods.setValue("promo", selectedData.promo);
      // setSelectedDate(selectedData.date);
    }
  }, []);

  const validateForm = (data, event) => {
    console.log(event);
    let { name, phone } = methods.getValues();

    if (name && `${phone}`.length == 11) {
      setShowErrors(false);

      submitFormCall({
        name,
        phone,
      });
    } else setShowErrors(true);
  };

  const onSubmitCall = methods.handleSubmit((data) => {
    validateForm(data, "call");
  });

  return (
    <>
      <Dialog
        open={errorMessage != ""}
        onClose={closeError}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{errorMessage}</DialogTitle>
        <DialogActions>
          <Button onClick={closeError} color="primary" autoFocus>
            Закрыть
          </Button>
        </DialogActions>
      </Dialog>
      <FormProvider {...methods}>
        <form>
          <Grid container spacing={2}>
            <OfferTextField
              name="name"
              label="ФИО"
              required
              error={showErrors && !methods.watch("name")}
            />
            <OfferPhoneField
              name="phone"
              label="Телефон"
              required
              error={showErrors && `${methods.watch("phone")}`.length != 11}
            />
            <Typography style={{ paddingLeft: "8px", paddingRight: "8px" }}>
              Мы гарантируем полную конфиденциальность полученных данных Телефон
              для справок: <a href="tel:+79857766701">+79857766701</a>
            </Typography>
          </Grid>
          <MobileStepper
            className={classes.buttonsContainer}
            variant="dots"
            steps={2}
            position="static"
            activeStep={0}
            nextButton={
              <Button
                variant="outlined"
                size="small"
                name="pay"
                onClick={() => {
                  onSubmitCall();
                }}
              >
                <PhoneCallback /> Свяжитесь со мной
              </Button>
            }
            backButton={
              <Button
                variant="outlined"
                size="small"
                name="call"
                onClick={() => {
                  onBack();
                }}
              >
                <KeyboardArrowLeft /> Назад
              </Button>
            }
          />
        </form>
      </FormProvider>
    </>
  );
}
