import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useForm, FormProvider } from "react-hook-form";
import {
  Grid,
  MobileStepper,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  Typography,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import OfferTextField from "../OfferTextField";
import OfferSelectField from "../OfferSelectField";
import OfferCheckboxField from "../OfferCheckboxField";
import OfferPhoneField from "../OfferPhoneField";
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  PhoneCallback,
  WhatsApp,
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  buttonsContainer: {
    marginTop: theme.spacing(1),
    background: "#ffffff00",
  },
}));

function formatSum(sum) {
  let str = `${sum}`;
  let resStr = "";
  for (let ii = str.length - 1; ii >= 0; ii--) {
    let num = str.length - ii - 1;
    if (num > 0 && num % 3 == 0) resStr = `${str[ii]}'${resStr}`;
    else resStr = `${str[ii]}${resStr}`;
  }
  return resStr;
}

const emailRegex =
  /^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

function isEmailValid(email) {
  if (!email) return false;

  if (email.length > 254) return false;

  var valid = emailRegex.test(email);
  if (!valid) return false;

  var parts = email.split("@");
  if (parts[0].length > 64) return false;

  var domainParts = parts[1].split(".");
  if (
    domainParts.some(function (part) {
      return part.length > 63;
    })
  )
    return false;

  return true;
}

function openWhatsApp() {
  window.open("https://wa.me/79167220123", "_blank");
}

export default function OfferForm({
  formData,
  submitFormCall,
  requestCall,
  submitFormPay,
  selectedData,
  errorMessage,
  closeError,
  gameFilter,
}) {
  const classes = useStyles();
  const methods = useForm();

  const [showErrors, setShowErrors] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [needContact, setNeedContact] = useState(false);

  useEffect(() => {
    if (selectedData.name) {
      methods.setValue("name", selectedData.name);
      methods.setValue("count", selectedData.count);
      methods.setValue("email", selectedData.email);
      methods.setValue("phone", selectedData.phone);
      methods.setValue("source", selectedData.source);
      methods.setValue("social", selectedData.social);
      methods.setValue("field", selectedData.field);
      // methods.setValue("promo", selectedData.promo);
      // setSelectedDate(selectedData.date);
      setNeedContact(selectedData.needContact);
    }
    let urlParams = new URLSearchParams(document.location.search);
    if (selectedData.date) {
      setSelectedDate(selectedData.date);
    } else {
      if (urlParams.get("date")) {
        setSelectedDate(urlParams.get("date"));
      }
    }
    if (selectedData.promo) {
      methods.setValue(selectedData.promo);
    } else {
      if (urlParams.get("promo")) {
        methods.setValue("promo", urlParams.get("promo"));
      }
    }
  }, []);

  // console.log(gameFilter);

  let dateOptions = formData.games
    .filter((g) => (gameFilter == "" ? true : g.gameName == gameFilter))
    .map((game) => ({
      private: game.private,
      value: `${game.id}`,
      label: `${game.date} ${game.openTime} — ${game.gameName} ${game.name}`,
    }));

  let dateObj = undefined;
  if (selectedDate)
    dateObj = formData.games.find((game) => game.id == selectedDate);

  if (dateObj && dateObj.private)
    dateOptions = [
      dateOptions.find((option) => option.value == `${dateObj.id}`),
    ];
  else {
    dateOptions = dateOptions.filter((option) => !option.private);
  }

  let fullPrice = 0;
  let sale = 0;
  if (dateObj) {
    let saleObj = dateObj.sale.find(
      (s) => s.count <= parseInt(methods.watch("count"))
    );
    let salePart = (saleObj && saleObj.sale) || 0;
    fullPrice = parseInt(methods.watch("count")) * dateObj.price;
    sale = parseInt((fullPrice * salePart) / 100);
    if (sale > fullPrice) sale = fullPrice;
  }

  const validateForm = (data, event) => {
    console.log(event);
    let { count, email, name, phone, promo, source, social, field } =
      methods.getValues();

    let date = parseInt(selectedDate);

    if (
      count > 0 &&
      isEmailValid(email) &&
      name &&
      `${phone}`.length == 11 &&
      date
    ) {
      setShowErrors(false);

      if (event == "pay")
        submitFormPay({
          count,
          email,
          name,
          phone,
          date,
          source,
          social,
          field,
          needContact,
          promo,
        });
      else
        submitFormCall({
          count,
          email,
          name,
          phone,
          date,
          source,
          social,
          field,
          needContact,
          promo,
        });
    } else setShowErrors(true);
  };

  const onSubmitPay = methods.handleSubmit((data) => {
    validateForm(data, "pay");
  });
  const onSubmitCall = methods.handleSubmit((data) => {
    validateForm(data, "call");
  });

  return (
    <>
      <Dialog
        open={errorMessage != ""}
        onClose={closeError}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{errorMessage}</DialogTitle>
        <DialogActions>
          <Button onClick={closeError} color="primary" autoFocus>
            Закрыть
          </Button>
        </DialogActions>
      </Dialog>
      <FormProvider {...methods}>
        <form>
          <Grid container spacing={2}>
            <OfferSelectField
              name="date"
              label="Дата"
              options={dateOptions}
              visible
              required
              helperText={(dateObj && dateObj.address) || ""}
              value={selectedDate}
              onChange={(e) => {
                setSelectedDate(e.target.value);
              }}
              error={showErrors && !selectedDate}
            />
            <OfferTextField
              name="name"
              label="ФИО"
              required
              error={showErrors && !methods.watch("name")}
            />
            <OfferPhoneField
              name="phone"
              label="Телефон"
              required
              error={showErrors && `${methods.watch("phone")}`.length != 11}
            />
            {/* <OfferTextField
						name="phone"
						label="Телефон"
						required
						error={showErrors && !methods.watch("phone")}
					/> */}
            <OfferTextField
              name="email"
              label="E-mail"
              required
              error={showErrors && !isEmailValid(methods.watch("email"))}
            />
            <OfferTextField
              name="count"
              label="Кол-во гостей"
              type="number"
              required
              helperText={
                (dateObj &&
                  methods.watch("count") > 0 &&
                  `К оплате ${formatSum(fullPrice - sale)}₽${
                    sale > 0 ? ` (включая скидку ${formatSum(sale)}₽)` : ""
                  }`) ||
                ""
              }
              error={showErrors && methods.watch("count") < 1}
            />
            {/* <OfferTextField name="field" label="Сфера деятельности" />
						<OfferTextField name="social" label="Аккаунт в соц сети" />
						<OfferTextField name="source" label="Как о нас узнали" /> */}
            <OfferTextField name="promo" label="Промокод/Код сертификата" />
            <Typography style={{ paddingLeft: "8px", paddingRight: "8px" }}>
              Мы гарантируем полную конфиденциальность полученных данных Телефон
              для справок: <a href="tel:+79857766701">+79857766701</a>
            </Typography>
            <OfferCheckboxField
              name="contact"
              label="Остались вопросы, перезвоните мне"
              checked={needContact}
              onChange={(event) => {
                setNeedContact(event.target.checked);
              }}
            />
          </Grid>
          <MobileStepper
            className={classes.buttonsContainer}
            variant="dots"
            steps={3}
            position="static"
            activeStep={0}
            nextButton={
              <Button
                variant="outlined"
                size="small"
                name="pay"
                onClick={() => {
                  onSubmitPay();
                }}
              >
                К оплате <KeyboardArrowRight />
              </Button>
            }
            backButton={
              <Button
                variant="outlined"
                size="small"
                name="pay"
                onClick={() => {
                  requestCall();
                }}
              >
                <PhoneCallback /> Свяжитесь со мной
              </Button>
            }
          />
        </form>
      </FormProvider>
    </>
  );
}
