import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useForm, FormProvider } from "react-hook-form";
import {
	Grid,
	MobileStepper,
	Button,
	Dialog,
	DialogTitle,
	DialogActions,
	IconButton,
	Tooltip,
} from "@material-ui/core";
import OfferTextField from "../OfferTextField";
import OfferSelectField from "../OfferSelectField";
import OfferCheckboxField from "../OfferCheckboxField";
import OfferPhoneField from "../OfferPhoneField";
import {
	KeyboardArrowLeft,
	KeyboardArrowRight,
	PhoneCallback,
	WhatsApp,
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
	buttonsContainer: {
		marginTop: theme.spacing(1),
		background: "#ffffff00",
	},
}));

function formatSum(sum) {
	let str = `${sum}`;
	let resStr = "";
	for (let ii = str.length - 1; ii >= 0; ii--) {
		let num = str.length - ii - 1;
		if (num > 0 && num % 3 == 0) resStr = `${str[ii]}'${resStr}`;
		else resStr = `${str[ii]}${resStr}`;
	}
	return resStr;
}

const emailRegex =
	/^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

function isEmailValid(email) {
	if (!email) return false;

	if (email.length > 254) return false;

	var valid = emailRegex.test(email);
	if (!valid) return false;

	var parts = email.split("@");
	if (parts[0].length > 64) return false;

	var domainParts = parts[1].split(".");
	if (
		domainParts.some(function (part) {
			return part.length > 63;
		})
	)
		return false;

	return true;
}

export default function CertificateOfferForm({
	formData,
	submitFormPay,
	selectedData,
	errorMessage,
	closeError,
}) {
	const classes = useStyles();
	const methods = useForm();

	const [showErrors, setShowErrors] = useState(false);
	const [selectedCertificate, setSelectedCertificate] = useState("");
	const [needContact, setNeedContact] = useState(false);

	useEffect(() => {
		if (selectedData.name) {
			methods.setValue("name", selectedData.name);
			methods.setValue("count", selectedData.count);
			methods.setValue("email", selectedData.email);
			methods.setValue("phone", selectedData.phone);
			methods.setValue("source", selectedData.source);
			methods.setValue("social", selectedData.social);
			methods.setValue("field", selectedData.field);
			// methods.setValue("promo", selectedData.promo);
			setSelectedCertificate(selectedData.certificate_type);
			setNeedContact(selectedData.needContact);
		}
		if (selectedData.certificate_type) {
			setSelectedCertificate(selectedData.certificate_type);
		}
		//  else {
		// 	let urlParams = new URLSearchParams(document.location.search);
		// 	if (urlParams.get("certificate")) {
		// 		setSelectedCertificate(urlParams.get("certificate"));
		// 	}
		// }
	}, []);

	const certificateOptions = formData.certificates.map((certificate) => ({
		value: `${certificate.id}`,
		label: `${certificate.name} — ${certificate.price}₽`,
	}));

	let certificateObj = undefined;
	if (selectedCertificate)
		certificateObj = formData.certificates.find((certificate) => certificate.id == selectedCertificate);

	const validateForm = (data, event) => {
		console.log(event);
		let { count, email, name, phone, source, social, field } =
			methods.getValues();

		let certificate_type = parseInt(selectedCertificate);

		if (
			count > 0 &&
			isEmailValid(email) &&
			name &&
			`${phone}`.length == 11 &&
			certificate_type
		) {
			setShowErrors(false);

			submitFormPay({
				count,
				email,
				name,
				phone,
				certificate_type,
				source,
				social,
				field,
				needContact,
			});
		} else setShowErrors(true);
	};

	const onSubmitPay = methods.handleSubmit((data) => {
		validateForm(data, "pay");
	});

	return (
		<>
			<Dialog
				open={errorMessage != ""}
				onClose={closeError}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{errorMessage}</DialogTitle>
				<DialogActions>
					<Button onClick={closeError} color="primary" autoFocus>
						Закрыть
					</Button>
				</DialogActions>
			</Dialog>
			<FormProvider {...methods}>
				<form>
					<Grid container spacing={2}>
						<OfferSelectField
							name="certificate_type"
							label="Сертификат"
							options={certificateOptions}
							visible
							required
							value={selectedCertificate}
							onChange={(e) => {
								setSelectedCertificate(e.target.value);
							}}
							error={showErrors && !selectedCertificate}
						/>
						<OfferTextField
							name="name"
							label="ФИО"
							required
							error={showErrors && !methods.watch("name")}
						/>
						<OfferPhoneField
							name="phone"
							label="Телефон"
							required
							error={showErrors && `${methods.watch("phone")}`.length != 11}
						/>
						{/* <OfferTextField
						name="phone"
						label="Телефон"
						required
						error={showErrors && !methods.watch("phone")}
					/> */}
						<OfferTextField
							name="email"
							label="E-mail"
							required
							error={showErrors && !isEmailValid(methods.watch("email"))}
						/>
						<OfferTextField
							name="count"
							label="Кол-во сертификатов"
							type="number"
							required
							helperText={
								(certificateObj &&
									methods.watch("count") > 0 &&
									`К оплате ${formatSum(
										certificateObj.price * methods.watch("count")
									)}₽`) ||
								""
							}
							error={showErrors && methods.watch("count") < 1}
						/>
						{/* <OfferTextField name="field" label="Сфера деятельности" />
						<OfferTextField name="social" label="Аккаунт в соц сети" />
						<OfferTextField name="source" label="Как о нас узнали" /> */}
						<OfferCheckboxField
							name="contact"
							label="Остались вопросы, перезвоните мне"
							checked={needContact}
							onChange={(event) => {
								setNeedContact(event.target.checked);
							}}
						/>
					</Grid>
					<MobileStepper
						className={classes.buttonsContainer}
						variant="dots"
						steps={3}
						position="static"
						activeStep={0}
						nextButton={
							<Button
								variant="outlined"
								size="small"
								name="pay"
								onClick={() => {
									onSubmitPay();
								}}
							>
								К оплате <KeyboardArrowRight />
							</Button>
						}
						backButton={
							<Button
								variant="outlined"
								size="small"
								style={{ visibility: "hidden" }}
							>
								<KeyboardArrowLeft /> Назад
							</Button>
						}
					/>
				</form>
			</FormProvider>
		</>
	);
}
